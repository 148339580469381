<template>
    <div style="min-width: 1200px;">

        <el-container>
            <el-header class="fa1">

                <div style="float: left;line-height: 60px;margin-left: 70px;">
                    拔刀科技
                </div>

                <div class="fa2">
                    <div class="fa6">
                        <span @click="routpush('home')">
                            首页
                        </span>
                    </div>
                    <div class="fa6">
                        <el-dropdown class="fa3" trigger="hover">
                            <span class="el-dropdown-link">
                                产品服务<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="fa4">
                                <el-dropdown-item @click.native="routpush('mark')">营销推广</el-dropdown-item>
                                <el-dropdown-item @click.native="routpush('virt')">虚拟充值</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <!-- <div class="fa6">
                        <span>
                            商务合作
                        </span>
                    </div> -->
                    <div class="fa6">
                        <span @click="routpush('aboutUs')">
                            关于拔刀
                        </span>
                    </div>
                </div>
            </el-header>
            <el-main style="padding: 0px;height: 93vh;">
                <router-view></router-view>
            </el-main>
        </el-container>

    </div>
</template>
<script>

export default {
    name: 'introduce',
    components: {
    },

    data() {
        return {

        }
    },
    mounted() {


    },

    methods: {
        routpush(val) {
            console.log(val, "vlavlalv");
            this.$router.push(val)
        },
    }
}

</script>

<style lang="less" scoped>
.fa1 {
    background: #141314;
    width: 100%;
    height: 60px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 0px;
}

.fa2 {
    width: 287px;
    float: right;
    height: 100%;
    line-height: 60px;

    >div {
        display: inline-block;
    }
}

.fa3 {
    color: #fff;
    font-size: 15px;
    cursor: pointer;
}

.fa4 {
    background: #141314;
    color: #fff;
}

.fa4 /deep/ .el-dropdown-menu__item {
    color: #fff;
}

.fa4 /deep/ .el-dropdown-menu__item:hover {
    color: #02C4D9;
    background: #141314;
}

.fa6 {
    // width: 80px;
    width: 28%;
    text-align: center;

    span {
        cursor: pointer;
    }
}
</style>
